import dynamic from 'next/dynamic';
import { CONTENTFUL_TAG_LOGGEDIN, CONTENTFUL_TAG_LOGGEDOUT } from '@constants/contentful';
import ContentfulRichTextRenderer from '@components/utils/ContentfulRichTextRenderer';
import { match } from '@utils/general';
import { carouselComponentMap, componentMap } from '@utils/mappings';

const ContentfulComponentMap = ({ isMobile = false, blocks, clientProps = { customer: undefined } }) => {
	return blocks.map((block, index) => {
		const key = block.handle ?? block.internalName ?? `${block.componentType}-${index}`;

		return match(block.componentType)
			.on(
				type => componentMap[type],
				type => {
					const Component = componentMap[type];
					const isComponentVisible = showComponent({ block, customer: clientProps.customer });

					return (
						isComponentVisible && (
							<Component
								key={key}
								isMobile={isMobile}
								{...(clientProps?.customer ? { customer: clientProps.customer } : {})}
								{...block}
							/>
						)
					);
				}
			)
			.on(
				x => x === 'componentText',
				() =>
					ContentfulRichTextRenderer(isMobile, {
						...block,
					})
			)
			.on(
				x => x === 'componentLink',
				() => {
					const Link = dynamic(() => import('@components').then(mod => mod.Link));

					return (
						<Link key={key} href={block.link ?? '#'} title={block.text}>
							{block.text}
						</Link>
					);
				}
			)
			.on(
				x => x === 'componentCollectionCarousel',
				() => {
					const props = {
						...block,
						customer: clientProps?.customer ?? null,
					};
					const Component = carouselComponentMap[block.carouselType];
					const isComponentVisible = showComponent({ block, customer: clientProps?.customer });

					if (!Component) return null;
					return isComponentVisible && <Component key={key} {...props} />;
				}
			)
			.on(
				x => x === 'plpMarketingBlock',
				() => {
					const InteractiveCard = dynamic(() => import('@components').then(mod => mod.InteractiveCard));

					const props: React.ComponentProps<typeof InteractiveCard> = {
						background: block.background?.url ?? null,
						title: block.title,
						subtitle: block.subtitle,
						text: block.text?.content[0].content[0].value ?? null,
						button1: {
							label: block.buttonLabel1,
							link: block.buttonLink1,
							color: block.buttonColor1,
							className: `marketingBlock-${index}-button-0`,
						},
						button2: block.buttonLabel2
							? {
								label: block.buttonLabel2,
								link: block.buttonLink2,
								color: block.buttonColor2,
								className: `marketingBlock-${index}-button-1`,
							}
							: null,
						shouldExpand: block?.shouldExpand,
						className: `marketingBlockContainer-${index}`,
						mediaClassName: `marketingBlock-${index}-image`,
						videoUrl: block?.videoUrl || null,
						shouldDisplayTitle: block?.shouldDisplayTitle,
						size: block?.size,
					};
					return <InteractiveCard key={key} {...props} />;
				}
			)
			.on(
				x => x === 'testimonialSlider',
				() => {
					const OldTestimonials = dynamic(() => import('@components').then(mod => mod.OldTestimonials));
					const Testimonials = dynamic(() => import('@components').then(mod => mod.Testimonials));

					if (block.useNewStyle) {
						return <Testimonials key={key} {...block} />;
					}

					return <OldTestimonials key={key} {...block} />;
				}
			)
			.on(
				x => x === 'visualAsset',
				() => {
					const props = {
						src: block.media.url,
						width: block.media.width,
						height: block.media.height,
						// TODO: find a way to remove hardcoded styles
						style: {
							maxWidth: 'fit-content',
							maxHeight: 25,
							height: '4.5vw',
						},
					};

					return <img key={key} alt={block.media.title} {...props} />;
				}
			)
			.otherwise(() => null);
	});
};

function showComponent({ block, customer }) {
	let show = true;
	const tags = block?.metadata?.tags.map(tag => tag.sys.id) ?? [];

	if (!customer) {
		if (tags.includes(CONTENTFUL_TAG_LOGGEDIN)) show = false;
	} else {
		if (tags.includes(CONTENTFUL_TAG_LOGGEDOUT)) show = false;
	}

	if (block.internalName === 'Explore All Tops' && tags.includes(CONTENTFUL_TAG_LOGGEDIN)) {
		show = true;
	}

	// Hard Code Block Visibility until LIHP goes live and we can make these changes in Contentful
	if (
		block.internalName === 'Home Page - Logged-In - Halloween' ||
		block.internalName === 'Home Page - Marketing Blocks' ||
		block.internalName === 'Home Page - Premium Tops - Hero'
	) {
		show = false;
	}

	if (block.internalName === 'Home Page - Logged-Out Carousel') {
		show = true;
	}

	return show;
}

export default ContentfulComponentMap;
