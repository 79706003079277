import dynamic from 'next/dynamic';

export const componentMap = {
	componentAccordion: dynamic(() => import('@components').then((mod) => mod.ComponentAccordion)),
	componentButton: dynamic(() => import('@components').then((mod) => mod.ContentfulButton)),
	componentButtonGrouping: dynamic(() => import('@components').then((mod) => mod.ButtonGrouping)),
	componentCountdownBanner: dynamic(() => import('@components').then((mod) => mod.CountdownBanner)),
	componentForYouCarousel: dynamic(() => import('@components').then((mod) => mod.ForYouCarousel)),
	componentFullWidthTextSection: dynamic(() => import('@components').then((mod) => mod.FullWidthTextSection)),
	componentHeroCarousel: dynamic(() => import('@components').then((mod) => mod.HeroCarousel)),
	componentLayout: dynamic(() => import('@components').then((mod) => mod.ComponentLayout)),
	componentLoggedInMarketingGrid: dynamic(() => import('@components').then((mod) => mod.LoggedInMarketingGrid)),
	componentLoggedInAccountCard: dynamic(() => import('@components').then((mod) => mod.LoggedInAccountCard)),
	componentLoggedInShopBy: dynamic(() => import('@components').then((mod) => mod.LoggedInShopBy)),
	componentMyFavoritesSlider: dynamic(() => import('@components').then((mod) => mod.MyFavoritesSlider)),
	componentProductList: dynamic(() => import('@components').then((mod) => mod.ProductList)),
	componentStorepoint: dynamic(() => import('@components').then((mod) => mod.Storepoint)),
	componentTwoPanelContentCard: dynamic(() => import('@components').then((mod) => mod.TwoPanelContentCard)),
	componentValueProposition: dynamic(() => import('@components').then((mod) => mod.ValueProposition)),
	componentCardsValueProposition: dynamic(() => import('@components').then((mod) => mod.CardValueProposition)),
	frequentlyAskedQuestions: dynamic(() => import('@components').then(mod => mod.Faqs)),
	blockGrid: dynamic(() => import('@components').then(mod => mod.BlockGrid)),
	hero: dynamic(() => import('@components').then((mod) => mod.Hero)),
	howItWorks: dynamic(() => import('@components').then((mod) => mod.HowItWorks)),
	imageTitleTextStack: dynamic(() => import('@components').then((mod) => mod.ImageTitleTextStack)),
	locationCard: dynamic(() => import('@components').then((mod) => mod.LocationCard)),
	marketingBlocks: dynamic(() => import('@components').then((mod) => mod.MarketingBlocks)),
	newsletter: dynamic(() => import('@components').then((mod) => mod.Newsletter)),
	promotionBanner: dynamic(() => import('@components').then(mod => mod.PromotionBanner)),
	tabCarousel: dynamic(() => import('@components').then(mod => mod.TabCarousel)),
	typeform: dynamic(() => import('@components').then((mod) => mod.Typeform)),
};

export const carouselComponentMap = {
	'Collection Tiles': dynamic(() => import('@components').then((mod) => mod.CollectionCarousel)),
	'Collection Tops Preview': dynamic(() => import('@components').then((mod) => mod.SneakPeekCarousel)),
	'Collection Slider': dynamic(() => import('@components').then((mod) => mod.FeaturedCollectionSlider)),
};
